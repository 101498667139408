<template>
  <b-row class="match-height">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row col="12">
          <b-col md="8" lg="8">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <!-- titulo -->
                  <b-col cols="12">
                    <b-form-group label-for="Titulo">
                      <label for="Titulo"
                        >Título <span class="text-danger">(*)</span></label
                      >
                      <validation-provider
                        #default="{ errors }"
                        name="Titulo"
                        rules="required"
                      >
                        <b-form-input
                          id="titulo"
                          v-model="pagina.titulo"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Titulo"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Contenido -->
                  <b-col cols="12">
                    <b-form-group label-for="Contenido">
                      <label for="Contenido"
                        >Contenido <span class="text-danger">(*)</span></label
                      >
                      <b-button
                        v-b-modal.modal-seleccionar-archivo
                        variant="flat-dark"
                        size="sm"
                      >
                        <feather-icon icon="ImageIcon" class="mr-50" />
                        <span class="align-left">Medios</span>
                      </b-button>
                      <b-button
                        v-b-modal.modal-seleccionar-enlace
                        variant="flat-dark"
                        size="sm"
                      >
                        <feather-icon icon="LinkIcon" class="mr-50" />
                        <span class="align-left">Enlaces</span>
                      </b-button>
                      <validation-provider
                        #default="{ errors }"
                        name="Contenido"
                        rules="required"
                      >
                        <CkEditorPersonalizado
                          ref="ckeditor"
                          v-model="pagina.contenido"
                        ></CkEditorPersonalizado>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-for="error in errores.contenido"
                          :key="error"
                          class="text-danger"
                          >{{ error }}</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="4" lg="4">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <!-- fecha -->
                  <b-col md="12">
                    <b-form-group
                      label="Fecha Publicación"
                      label-for="Fecha Publicacion"
                    >
                      <b-form-datepicker
                        id="example-datepicker"
                        v-model="pagina.published_at"
                        placeholder="Seleccione una fecha"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- estado -->
                  <b-col md="12">
                    <b-form-group label="Estado" label-for="Estado">
                      <v-select
                        id="blog-edit-estado"
                        v-model="estadoa"
                        :disabled="!$hascan('pagina.publicar')"
                        label="title"
                        :options="estadoOpciones"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Categorias -->
                  <!-- <b-col md="12">
                    <b-form-group label="Pagina Superior" label-for="pagina-sup">
                      <v-select
                        id="paniga-sup"
                        v-model="categoria_id"
                        label="title"
                        :options="categoriaOpciones"
                        :clearable="false"
                      >
                      </v-select>
                    </b-form-group>
                  </b-col> -->

                  <!-- Imagen -->
                  <b-col md="12">
                    <b-form-group
                      label="Foto Principal"
                      label-for="Foto Principal"
                    >
                      <div class="box-imagen box-imagen1 border rounded">
                        <b-img
                          v-if="imagen"
                          :src="imagen"
                          center
                          fluid
                          thumbnail
                          class="rounded"
                          alt="Card image cap"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-b-modal.modal-seleccionar-imagen
                      variant="flat-dark"
                      size="sm"
                    >
                      <feather-icon icon="ImageIcon" class="mr-50" />
                      <span class="align-middle">Elegir Imagen</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-50">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="guardar"
                    >
                      Guardar
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      :to="{ name: 'paginas' }"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--Componente Categoria -->
    <CategoriaNuevoEditar
      :accion="accionCategoria"
      :item="itemCategoria"
      @cerrarComponente="cerrarComponenteCategoria"
      @actualizarLista="actualizarListaCategoria"
    >
    </CategoriaNuevoEditar>
    <!--Componente Etiquetas -->
    <EtiquetaNuevoEditar
      :accion="accionEtiqueta"
      :item="itemEtiqueta"
      @cerrarComponente="cerrarComponenteEtiqueta"
      @actualizarLista="actualizarListaEtiqueta"
    >
    </EtiquetaNuevoEditar>
    <!--Componente Medios -->
    <MediosComponent @enviarMedia="recibirMedia"></MediosComponent>
    <!--Componente Imagen -->
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
    <EnlacesComponent @enviarUrl="recibirUrl"></EnlacesComponent>
  </b-row>
</template>
<script>
import axios from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BFormDatepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import router from "@/router";
import CategoriaNuevoEditar from "@/views/aplicaciones/configuracion/categoria/CategoriaNuevoEditar.vue";
import EtiquetaNuevoEditar from "@/views/aplicaciones/configuracion/etiqueta/EtiquetaNuevoEditar.vue";
import MediosComponent from "@/componentes/MediosComponent.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado.vue";
import EnlacesComponent from "@/componentes/EnlacesComponent.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BImg,
    BFormFile,
    BFormDatepicker,

    CategoriaNuevoEditar,
    EtiquetaNuevoEditar,
    MediosComponent,
    ImagenComponent,
    CkEditorPersonalizado,
    EnlacesComponent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: null,
      imagen: "",
      pagina: {
        titulo: "",
        descripcion: "",
        contenido: "",
        published_at: null,
        imagen: "",
        tipo_entrada: "paginas",
        tipo_entrada_id: 2,
        categoria_id: 1,
        etiquetas_id: [],
      },
      estadoOpciones: [
        { id: 1, title: "Publicado" },
        { id: 2, title: "Borrador" },
        { id: 3, title: "Papelera" },
      ],
      estadoa: { id: 2, title: "Borrador" },
      required,
      submitted: false,
      errores: [],
      editorOpcion: {
        placeholder: "Ingrese el contenido aqui ...",
      },
      categoria_id: null,
      categoriaOpciones: [],
      etiquetas_id: [],
      etiquetasOpciones: [],

      editorConfig: {},
      accionCategoria: "",
      itemCategoria: [],
      accionEtiqueta: "",
      itemEtiqueta: [],
    };
  },
  created() {
    this.date();
    if (router.currentRoute.params.id) {
      axios
        .get(`/admin/entradas/${router.currentRoute.params.id}`)
        .then((response) => {
          this.pagina = response.data;
          // this.convocatoria.categoria_id =
          //   this.convocatoria.entrada.categoria_id;

          // this.pagina.titulo = this.pagina.titulo;
          // this.pagina.url = this.pagina.url;
          this.imagen = this.pagina.imagen;
          // this.pagina.published_at =
          //   this.pagina.published_at;
          // this.convocatoria.descripcion = this.convocatoria.entrada.descripcion;
          // this.convocatoria.contenido = this.convocatoria.entrada.contenido;
          this.estadoa.id = this.pagina.estado;
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === this.pagina.estado
          );
          this.estadoa = { id: valor.id, title: valor.title };
        })
        .catch((error) => console.error(error));
    }
  },
  methods: {
    date() {
      const hoy = new Date();
      var date = hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
      var time = hoy.getHours() + ":" + hoy.getMinutes();
      this.pagina.published_at = date + " " + time;
    },

    listarEtiquetas() {
      var url = "/admin/etiquetas";
      axios
        .get(url)
        .then((res) => {
          this.etiquetasOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarCategorias() {
      var url = "/admin/categorias";
      axios
        .get(url)
        .then((res) => {
          this.categoriaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //categoria
    actualizarListaCategoria(item) {
      this.listarCategorias();
      this.categoria_id = { title: item.nombre, value: item.id };
      this.accionCategoria = "";
      this.itemCategoria = [];
    },
    abriComponenteCategoria(item) {
      this.accionCategoria = "actualizar";
      //this.itemCategoria=item;
    },
    cerrarComponenteCategoria() {
      this.accionCategoria = "";
      this.itemCategoria = [];
    },

    //etiqueta
    actualizarListaEtiqueta(item) {
      this.listarEtiquetas();
      this.etiquetas_id.push({ title: item.nombre, value: item.id });
      this.accionEtiqueta = "";
      this.itemEtiqueta = [];
    },
    abriComponenteEtiqueta(item) {
      this.accionEtiqueta = "actualizar";
      //this.itemEtiqueta=item;
    },
    cerrarComponenteEtiqueta() {
      this.accionEtiqueta = "";
      this.itemEtiqueta = [];
    },

    //RECIBIR MEDIA DEL COMPONENTE
    recibirMedia(media) {
      if (media.tipo == "IMAGEN") {
        //this.$refs.ckeditor.editorCk
        const content = `<figure class="image"><img src="${media.url}"></figure>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else if (media.tipo == "VIDEO") {
        if (media.enlace) {
          const content = `<figure class="media"><oembed url="${media.url}"></oembed></figure>`;
          const viewFragment =
            this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment =
            this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(
            modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        } else {
          const content = `
            <div class="raw-html-embed" style="text-align:center;">
              <video width="540" controls>
                <source src="${media.url}" type="video/mp4">
              </video>
            </div>`;
          const viewFragment =
            this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment =
            this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(
            modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }
      } else if (media.tipo == "AUDIO") {
        if (media.enlace) {
          const content = ` 
            <div class="raw-html-embed" style="text-align:center;">
              <iframe src="${media.url}" width="50%" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            </div>`;
          const viewFragment =
            this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment =
            this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(
            modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        } else {
          const content = ` 
            <div class="raw-html-embed" style="text-align:center;">
              <audio controls="">
                <source src="${media.url}" type="audio/mpeg">
              </audio>
            </div>`;
          const viewFragment =
            this.$refs.ckeditor.editorCk.data.processor.toView(content);
          const modelFragment =
            this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
          this.$refs.ckeditor.editorCk.model.insertContent(
            modelFragment,
            this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
          );
        }
      } else if (media.tipo == "DOCUMENTO") {
        const content = ` 
          <div class="raw-html-embed" style="text-align:center;">
            <iframe src="${media.url}" width="80%" height="720px"></iframe>
          </div>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      } else {
        const content = `<a href="${media.url}">${media.titulo}</a>`;
        const viewFragment =
          this.$refs.ckeditor.editorCk.data.processor.toView(content);
        const modelFragment =
          this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
        this.$refs.ckeditor.editorCk.model.insertContent(
          modelFragment,
          this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
        );
      }
    },
    // RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen) {
      this.imagen = imagen.url;
    },
    //RECIBIR URL
    recibirUrl(enlace) {
      //console.log(enlace);
      var itemEnlace = enlace;

      const content = `<a href="${itemEnlace.url}">${itemEnlace.titulo}</a>`;
      const viewFragment =
        this.$refs.ckeditor.editorCk.data.processor.toView(content);
      const modelFragment =
        this.$refs.ckeditor.editorCk.data.toModel(viewFragment);
      this.$refs.ckeditor.editorCk.model.insertContent(
        modelFragment,
        this.$refs.ckeditor.editorCk.model.document.selection.getFirstPosition()
      );
    },

    validar() {
      this.$refs.eventoRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    async guardar() {
      this.pagina.imagen = this.imagen;
      this.pagina.estado = this.estadoa.id;
      //console.log("hola editar");
      if (this.pagina.id) {
        axios
          .put(`/paginas/${this.pagina.id}`, this.pagina)
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post("/paginas", this.pagina)
          .then((response) => {
            this.success("Insertado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "paginas" });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>